import React from "react"
import { graphql, PageProps } from "gatsby"
import { WindowLocation } from "@reach/router"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"
interface CvData {
  work: Array<{
    currentRole: boolean
    finished: string
    location: string
    name: string
    roleBlurb: string
    started: string
    howLongAgo: string
    title: string
  }>
  education: Array<{
    degree: string
    finished: string
    location: string
    name: string
    roleBlurb: string
    started: string
    howLongAgo: string
  }>
}

interface ProfessionalContentProps {
  cvData: CvData
  location: WindowLocation
}

export const ProfessionalContent: React.FC<ProfessionalContentProps> = ({
  cvData,
  location,
}) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Professional" description="Liam Myles's professional CV" />
      <h1>CV</h1>
      <h2>Work</h2>
      {cvData.work.map(
        (
          {
            name,
            title,
            roleBlurb,
            location,
            howLongAgo,
            started,
            currentRole,
            finished,
          },
          index
        ) => {
          return (
            <React.Fragment key={`work-cv-${index}`}>
              <p>
                <strong>{name}</strong>
              </p>
              <p>
                {location} -{" "}
                {currentRole
                  ? `${started} to present`
                  : `${started} to ${finished}`}{" "}
                ({howLongAgo})
              </p>
              <p>{title}</p>
              <p>{roleBlurb}</p>
            </React.Fragment>
          )
        }
      )}
      <h2>Education</h2>
      {cvData.education.map(
        (
          { name, degree, finished, howLongAgo, location, roleBlurb, started },
          index
        ) => {
          return (
            <React.Fragment key={`education-cv-${index}`}>
              <p>
                <strong>{name}</strong>
              </p>
              <p>
                {location} - {started} to {finished} ({howLongAgo})
              </p>
              <p>{degree}</p>
              <p>{roleBlurb}</p>
            </React.Fragment>
          )
        }
      )}
    </StandardLayout>
  )
}

interface ProfessionalPageData {
  dataJson: {
    cv: CvData
  }
}

const ProfessionalPage: React.FC<PageProps<ProfessionalPageData>> = ({
  data,
  location,
}) => <ProfessionalContent cvData={data.dataJson.cv} location={location} />

export default ProfessionalPage

export const query = graphql`
  query {
    dataJson {
      cv {
        education {
          degree
          finished
          location
          name
          roleBlurb
          started
          howLongAgo: started(fromNow: true)
        }
        work {
          name
          title
          location
          currentRole
          started
          howLongAgo: started(fromNow: true)
          finished
          roleBlurb
        }
      }
    }
  }
`
